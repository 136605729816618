<template>
  <v-row justify="center">
    <v-dialog :value="dialog" @input="$emit('dialog')" max-width="416">
      <v-card class="success-modal">
        <v-btn icon rounded x-small color="primarylight3" class="success-modal__close-btn border" @click="close"
          ><v-icon>mdi-close</v-icon></v-btn
        >
        <img src="@/assets/icons/green-tick-rounded.svg" alt="ok" class="success-modal__icon" />
        <h3 class="success-modal__title">Thank you for entering data</h3>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "Success",
  props: ["dialog"],
  methods: {
    close() {
      this.$emit("dialog");
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
.success-modal {
  position: relative;
  text-align: center;
  padding: 52px 0;
  &__title {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
  }
  &__close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  &__icon {
    width: 64px;
    height: 64px;
    margin: 0 auto;
    text-align: center;
  }
}
</style>
