<template>
  <v-dialog :value="sickModal" max-width="416" @input="$emit('sickModal')" @click:outside="closeDialog">
    <v-card class="sick-modal">
      <v-btn class="sick-modal__close-btn border" color="primarylight3" icon rounded x-small @click="closeDialog">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <img alt="warning" class="sick-modal__icon" src="@/assets/warning-circle.svg" />
      <h3 class="sick-modal__title mb-2">Your Data has been saved</h3>
      <h3 v-for="(text, index) in sickModalText" :key="index" class="sick-modal__title">{{ text }}</h3>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "pinia";

import { useObservationsStore } from "@/pinia-store/observations";

export default {
  name: "Sick",
  computed: {
    ...mapState(useObservationsStore, ["sickModal", "sickModalText"]),
  },
  methods: {
    ...mapActions(useObservationsStore, ["closeSickModal"]),
    closeDialog() {
      this.$emit("sickModal");
      this.closeSickModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.sick-modal {
  position: relative;
  text-align: center;
  padding: 52px 0;

  &__title {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
  }

  &__close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  &__icon {
    width: 64px;
    height: 64px;
    text-align: center;
    margin: 0 auto 27px;
  }
}
</style>
